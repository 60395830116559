// Delay function
var delay = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();

var injector_nodig = 0, injector_waarde = 0;

$.validator.setDefaults({
    highlight: function(element) {
        $(element).closest('.form-group').addClass('has-error');
    },
    unhighlight: function(element) {
        $(element).closest('.form-group').removeClass('has-error');
    },
    errorElement: 'span',
    errorClass: 'help-block',
    errorPlacement: function(error, element) {
        if(element.parent('.input-group').length) {
            //error.insertAfter(element.parent());
            error.appendTo(element.parent().parent());
        } else {
            error.insertAfter(element);
        }
    }
});

$(document).ready(function() {
    $.ajaxSetup ({
        cache: false,
        global: false
    });

    $("body").fitVids();

    $('.visual-bg').each(function() {
        if (typeof($(this).data('src')) != 'undefined') $(this).css('backgroundImage', 'url(' + $(this).data('src') + ')' );
        $(this).find('.loader').remove();
    });

    $('header .flexslider').flexslider({
        animation: "fade",
        slideshowSpeed: 3000,
        pauseOnHover: true,
        controlNav: false,
        directionNav: false,
        useCSS: false
    });

    $('#project .flexslider').flexslider({
        animation: "fade",
        slideshowSpeed: 3000,
        pauseOnHover: true,
        controlNav: true,
        directionNav: false,
        useCSS: false
    });

    $('.zerniq-wysiwyg .fancybox').fancybox({
        openEffect : 'none',
        closeEffect    : 'none',
        helpers : {
            title : {
                type : 'over'
            }
        }
    });

    $('.zerniq-wysiwyg .fancybox-iframe').fancybox({
        openEffect : 'none',
        closeEffect    : 'none',
        type: 'iframe',
        width            : 960,
        height        : 586,
        autoResize    : true,
        padding        : 10,

        helpers : {
            title : {
                type : 'over'
            }
        }
    });

    $('a.filter').click(function() {
        window.location.hash = $(this).data('filter').replace('.', '');
    });

    var filterOnLoad = window.location.hash ? '.'+(window.location.hash).replace('#','') : 'all';

    $('#mixcontainer').mixItUp({
        controls: {
            enable: true
        }, load: {
            filter: filterOnLoad,
        }

    });

   /* CONTAINER CLICKABLE */
    $('.click-box').click( function() {
        window.location.href = $(this).find('a').attr('href');
    });


    $("#contact-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/contact.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    $(form)[0].reset();
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });
	
	
    $("#searchfor").keyup(function(){
        // Delay function invoked to make sure user stopped typing
        delay(function(){
            var inputText = $("#searchfor").val();
			var params = []
			params.push({name: 'qs', value: inputText});
			$.post(window.base_url +"engine/quicksearch.php", params, function(data){
                if(data.succes)
                {
					$('#search-results').html('<p></p>');
					$.each(data.found, function(key, value) {
						$('#search-results p').append('<a href="' + value.document + '" target="_blank">' + value.titel + '</a><br>');
					});
					$('#search-nothing').addClass('hidden');
					$('#search-feedback').removeClass('hidden');
					$('#search-feedback span.aantal').html(data.found.length);
					$('#search-feedback span.totaal').html(data.total);
                } else
                {
					$('#search-results').html('');
					$('#search-nothing').removeClass('hidden');
					$('#search-feedback').addClass('hidden');
                }
            }, 'json');
        }, 200 );
    });

    if (typeof(alerts) != 'undefined') {
        $.each(alerts, function(key, alert_msg) {
            alert(alert_msg.value);
        });

    }
	
	$('#r115-option').change(function(e) {
		var params = []
		params.push({name: 'r115', value: $(this).val() });
		$.post(window.base_url +"engine/r115.php", params, function(data){
			$('#r115-results').html('');
			if(data.succes)
			{
				var html_txt = '';
				
				
				html_txt = '<p><b>LPi</b><br>';
				if (data.result.lpi_euro34.length > 0) {
					html_txt = html_txt + 'Euro3/4:<br>';
					$.each(data.result.lpi_euro34, function(key, value) {
						html_txt = html_txt + '<a href="' + value.bestand + '" target="_blank">' + value.titel + '</a> ';
					});
					html_txt = html_txt + '<br>';
				}
				if (data.result.lpi_euro5.length > 0) {
					html_txt = html_txt + 'Euro5:<br>';
					$.each(data.result.lpi_euro5, function(key, value) {
						html_txt = html_txt + '<a href="' + value.bestand + '" target="_blank">' + value.titel + '</a> ';
					});
					html_txt = html_txt + '<br>';
				}
				if (data.result.lpi_euro6.length > 0) {
					html_txt = html_txt + 'Euro6:<br>';
					$.each(data.result.lpi_euro6, function(key, value) {
						html_txt = html_txt + '<a href="' + value.bestand + '" target="_blank">' + value.titel + '</a> ';
					});
					html_txt = html_txt + '<br>';
				}
				html_txt = html_txt + '</p>';
				
				html_txt = html_txt + '<p><b>LiquidSi</b><br>';
				if (data.result.liquidsi_euro34.length > 0) {
					html_txt = html_txt + 'Euro3/4:<br>';
					$.each(data.result.liquidsi_euro34, function(key, value) {
						html_txt = html_txt + '<a href="' + value.bestand + '" target="_blank">' + value.titel + '</a> ';
					});
					html_txt = html_txt + '<br>';
				}
				if (data.result.liquidsi_euro5.length > 0) {
					html_txt = html_txt + 'Euro5:<br>';
					$.each(data.result.liquidsi_euro5, function(key, value) {
						html_txt = html_txt + '<a href="' + value.bestand + '" target="_blank">' + value.titel + '</a> ';
					});
					html_txt = html_txt + '<br>';
				}
				if (data.result.liquidsi_euro6.length > 0) {
					html_txt = html_txt + 'Euro6:<br>';
					$.each(data.result.liquidsi_euro6, function(key, value) {
						html_txt = html_txt + '<a href="' + value.bestand + '" target="_blank">' + value.titel + '</a> ';
					});
					html_txt = html_txt + '<br>';
				}
				html_txt = html_txt + '</p>';
				
				html_txt = html_txt + '<p><b>LPdi</b><br>';
				if (data.result.lpdi_euro34.length > 0) {
					html_txt = html_txt + 'Euro3/4:<br>';
					$.each(data.result.lpdi_euro34, function(key, value) {
						html_txt = html_txt + '<a href="' + value.bestand + '" target="_blank">' + value.titel + '</a> ';
					});
					html_txt = html_txt + '<br>';
				}
				if (data.result.lpdi_euro5.length > 0) {
					html_txt = html_txt + 'Euro5:<br>';
					$.each(data.result.lpdi_euro5, function(key, value) {
						html_txt = html_txt + '<a href="' + value.bestand + '" target="_blank">' + value.titel + '</a> ';
					});
					html_txt = html_txt + '<br>';
				}
				if (data.result.lpdi_euro6.length > 0) {
					html_txt = html_txt + 'Euro6:<br>';
					$.each(data.result.lpdi_euro6, function(key, value) {
						html_txt = html_txt + '<a href="' + value.bestand + '" target="_blank">' + value.titel + '</a> ';
					});
					html_txt = html_txt + '<br>';
				}
				html_txt = html_txt + '</p>';
				$('#r115-results').html('<h3>' + data.result.titel + '</h3>' + html_txt);
			} else
			{
				
			}
		}, 'json');
	});


    $("#actie-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/actie.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    $(form)[0].reset();
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });

    $('#toggle-menu').click(function(e) {
        e.preventDefault();
        if ($('.menu').hasClass('show'))
        {
            $('.menu').removeClass('show');
            $(this).text('Menu tonen');
        } else
        {
            $('.menu').addClass('show');
            $(this).text('Menu verstoppen');
        }
    });

    $('#content img').addClass('img-responsive');

    if ($('#gmap').length > 0)
    {
        $("#gmap").gmap3({
            marker: {
                address: 'Leemkuil 7 5626 EA Eindhoven',
                events: {
                    click: function(marker, event, data)     {
                        window.open("https://maps.google.com/?daddr=Leemkuil+7,+5626+EA+Eindhoven");
                    }
                }
            },
            map:{
                options:{
                    zoom:13
                }
            }
        });
    }


    $("#login-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/login.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    window.location.href = window.location.href;
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });

    $("#password-form").validate({
        rules: {
            controle_wachtwoord: {
                equalTo: '#nieuw_wachtwoord'
            }
        },
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/password.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    window.location.href = window.location.href;
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });

    if ($('#dealer-map').length > 0)
    {
        $("#dealer-map").gmap3({
            map:{
                address: $('#land option:selected').text(),
                options:{
                    mapTypeId: google.maps.MapTypeId.ROAD,
                    zoom: 7
                }
            },
            marker: {
                address: $('#land option:selected').text()
            }
        });

        $('#land').change(function(e) {
            $.post(window.base_url +"engine/dealers.php", $('#landkeuze').serializeArray(), function(data) {
                if (data.succes)
                {
                    $("#dealer-map").gmap3({
                        getlatlng:{
                            address: data.middenadres,
                            callback: function(results){
                                if ( !results ) return;
                                $("#dealer-map").gmap3('get').panTo(results[0].geometry.location);
                                $("#dealer-map").gmap3('get').setZoom(parseInt(data.zoom));
                                $("#dealer-map").gmap3({
                                    clear: {
                                        name:["marker"]
                                    }
                                });
                                $("#dealer-map").gmap3({
                                    marker: {
                                        values: data.markers,
                                        events:{
                                            click: function(marker, event, context){
                                                var info = '';
                                                info = '<p>';
                                                info = info + context.data.naam + '<br>';
                                                info = info + context.data.adres + '<br>';
                                                info = info + context.data.postcode + '<br>';
                                                info = info + context.data.stad + '</p>';
                                                info = info + '<p>';
                                                info = info + context.data.toevoeging + '</p>';
                                                info = info + '<p>';
                                                if (context.data.telefoon.length > 0) info = info + context.data.telefoon + '<br>';
                                                if (context.data.telefoon_alt.length > 0) info = info + context.data.telefoon_alt + '<br>';
                                                if (context.data.website.length > 0) info = info + '<a href="' + context.data.website + '" target="_blank">' + context.data.website + '</a><br>';
                                                if (context.data.email.length > 0) info = info + '<a href="mailto:' + context.data.email + '">' + context.data.email + '</a><br>';
                                                info = info + '</p>';

                                                info = info + '<p><a href="http://maps.google.com/?daddr=' + context.data.adres + ' ' + context.data.postcode + ' ' + context.data.stad + '" target="_blank">Route</a></p>';
												/*
                                                $(this).gmap3(
                                                    {clear:"overlay"},
                                                    {
                                                        overlay:{
                                                            latLng: marker.getPosition(),
                                                            options:{
                                                                content:  "<div class='infobox'>" + info +
                                                                        "</div>"
                                                            }
                                                        }
                                                    }
                                                );
                                                $("#dealer-map").gmap3('get').panTo(marker.getPosition());
												*/
												$(this).gmap3({
													clear:"infowindow",
													infowindow: {
														latLng: marker.getPosition(),
														options:{
															content:  "<div class='infobox'>" + info +
                                                                        "</div>",
															pixelOffset: new google.maps.Size(0, -30)
															}
														},
												});
												$(this).gmap3('get').panTo(marker.getPosition());
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    });
                    $('#dealer-land span').html(data.land);
                    $('#dealer-list li').remove();
                    $.each(data.markers, function(cell, value) {
                        var $dealer = $('<li>');
                        $dealer.html('<p>');
                        $dealer.html($dealer.html() + value.data.naam + '<br>');
                        $dealer.html($dealer.html() + value.data.adres + '<br>');
                        $dealer.html($dealer.html() + value.data.postcode + '<br>');
                        $dealer.html($dealer.html() + value.data.stad + '</p>');
                        $dealer.html($dealer.html() + '<p>');
                        $dealer.html($dealer.html() + value.data.toevoeging + '</p>');
                        $dealer.html($dealer.html() + '<p>');
                        if (value.data.telefoon.length > 0) $dealer.html($dealer.html() + value.data.telefoon + '<br>');
                        if (value.data.telefoon_alt.length > 0) $dealer.html($dealer.html() + value.data.telefoon_alt + '<br>');
                        if (value.data.website.length > 0) $dealer.html($dealer.html() + '<a href="' + value.data.website + '" target="_blank">' + value.data.website + '</a><br>');
                        if (value.data.email.length > 0) $dealer.html($dealer.html() + '<a href="mailto:' + value.data.email + '">' + value.data.email + '</a><br>');
                        $dealer.html($dealer.html() + '</p>');
                        $dealer.html($dealer.html() + '<hr>');
                        $dealer.appendTo($('#dealer-list'));
                    });
                }
            }, 'json');

        });
        $('#land').trigger('change');
    }
	$('input[type=number]').on('focus', function() {
		if ($(this).val() == '0') $(this).val('');
	});
	$('input[type=number]').on('blur', function() {
		if ($(this).val() == '') $(this).val('0');
	});
    $('.single-product img, .kit-list img, .shop-header-inhoud img').each(function(e) {
        var src = $(this).prop('src');
        var $a = $('<a>');
        var $parent = $(this).parent();
        //$a.html($(this));
        $a.prop('href', src);
        $a.addClass('fancybox');
        $(this).appendTo($a);
        $parent.append($a);
    });
    
    $('.dealer-shop .fancybox').fancybox({
        openEffect : 'none',
        closeEffect    : 'none',
        helpers : {
            title : {
                type : 'over'
            }
        }
    });
	$('#spares-zoeken').keyup(function() {
		var searchval = $(this).val().toUpperCase();
		$('.producten-lijst .single-product').each(function() {
			if ($(this).text().toUpperCase().indexOf(searchval) > -1)
				$(this).removeClass('hidden');
			else
				$(this).addClass('hidden');
		});
		if ($('.producten-lijst .single-product:not(.hidden)').length > 100) {
			$('.producten-lijst .alert').removeClass('hidden');
		} else {
			$('.producten-lijst .alert').addClass('hidden');
		}
	});

	$('.add-to-cart').click(function(e) {
		e.preventDefault();
		var params = [];
		params.push({name: 'aantal[' + $(this).data('ref') + ']', value: $(this).parent().parent().find('input').val() } );
		$.post(window.base_url +"engine/cart.add.php", params, function(data){
			$('#cart-info-aantal').text(data.aantal);
			$('#cart-info-prijs').text(data.prijs);
			if (data.aantal > 0) {
				$('#cart-info-next').removeClass('hidden');
			} else {
				$('#cart-info-next').addClass('hidden');
			}
		}, 'json');
	});
	$('.update-cart').click(function(e) {
		e.preventDefault();
		var params = [];
		var prod_id = $(this).data('ref');
		params.push({name: 'aantal[' + $(this).data('ref') + ']', value: $(this).parent().parent().find('input').val() } );
		params.push({name: 'source', value: 'cart'});
		$.post(window.base_url +"engine/cart.add.php", params, function(data){
			$('#cart-info-aantal').text(data.aantal);
			$('#cart-info-prijs').text(data.prijs);
			if (data.aantal_stuk == 0) {
				$('#line-' + prod_id).remove();
			} else {
				$('#sum-' + prod_id).text(data.prijs_stuk);
			}
		}, 'json');
	});
	
	$('.delete-cart').click(function(e) {
		e.preventDefault();
		var params = [];
		var prod_id = $(this).data('ref');
		params.push({name: 'aantal[' + $(this).data('ref') + ']', value: 0 } );
		params.push({name: 'source', value: 'cart'});
		$.post(window.base_url +"engine/cart.add.php", params, function(data){
			$('#cart-info-aantal').text(data.aantal);
			$('#cart-info-prijs').text(data.prijs);
			if (data.aantal_stuk == 0) {
				$('#line-' + prod_id).remove();
			} else {
				$('#sum-' + prod_id).text(data.prijs_stuk);
			}
			if (data.aantal == 0) {
				window.location.href = window.base_seourl + '/dealers/dealer-shop';
			}
		}, 'json');
	});
	
	$('#shop-controle').click(function(e) {
		e.preventDefault();
		$('#order-gegevens').submit();
	});
	
	$('#order-gegevens').validate({
        submitHandler: function(form) {
			var params = $(form).serializeArray();
            $.post(window.base_url +"engine/cart.save.php", params, function(data){
				if (data.succes) {
					window.location.href = window.base_seourl + '/dealers/dealer-shop/validate';
				} else {
					alert('Sorry, order could not be created');
				}
            }, 'json');
        }
	});

    $('#shop-afronden').click(function(e) {
        e.preventDefault();
        if ($('#akkoord:checked').length > 0) {
            $('#shop-order-method').val('proforma');
            $('#order-controle').submit();
        } else
            alert('Please agree to the terms');
    });

    $('#shop-afronden-pay').click(function(e) {
        e.preventDefault();
        if ($('#akkoord:checked').length > 0) {
            $('#shop-order-method').val('betalen');
            $('#order-controle').submit();
        } else
            alert('Please agree to the terms');
    });

	$('#order-controle').validate({
        submitHandler: function(form) {
			var params = $(form).serializeArray();
            $.post(window.base_url +"engine/cart.submit.php", params, function(data){
				if (data.succes) {
                    window.location.href = data.url;
				} else {
					alert('Sorry, order could not be created');
				}
            }, 'json');
        }
	});

	$('.kit-verder').click(function(e) {
		e.preventDefault();
		var kitref = $(this).data('ref');
        if ($('#kit-stap-'+ kitref).data('injn') == 'Ja') {
            injector_waarde = 0;
            $('#kit-stap-'+ kitref + ' input').each(function() {
				injector_waarde+= ( $(this).val() * $(this).data('injector') );
			});     
            if (injector_waarde > injector_nodig) {
				alert('You have not selected the correct injector count. You need to select a max of ' + injector_nodig);
				return;
			}
        }
        if ($('#kit-stap-'+ kitref).data('injw') == 'Ja') {
            injector_nodig = 0;
            $('#kit-stap-'+ kitref + ' input').each(function() {
				injector_nodig+= ( $(this).val() * $(this).data('injector') );
			});
        }
		if ($('#kit-stap-'+ kitref).data('verplicht') == 'Ja') {
            if ($('#kit-stap-'+ kitref).data('single') == 'Ja') {
                if ($('#select-kit-stap-'+ kitref).val().length == 0) {
                    alert('Please select the product you want to order');
                    return;                
                }
            } else {
                var kitcount = 0;
                $('#kit-stap-'+ kitref + ' input').each(function() {
                    kitcount+= Number.parseInt($(this).val());
                });
                if (kitcount == 0) {
                    alert('Please select atleast one product you want to order');
                    return;
                }
                var min_count = $('#kit-stap-'+ kitref).data('min');
                var max_count = $('#kit-stap-'+ kitref).data('max');
                if (Number.isInteger(min_count)) {
                    if (kitcount < min_count) {
                        alert('Please select atleast ' + min_count + ' products you want to order');
                        return;
                    }
                }
                if (Number.isInteger(max_count)) {
                    if (kitcount > max_count) {
                        alert('Please select maximum ' + min_count + ' products you want to order');
                        return;
                    }
                }
            }
		}
		$('#kit-stap-'+ kitref).removeClass('in');
		$('#kit-stap-'+ kitref).next().next().addClass('in');
	});

	$('.kit-terug').click(function(e) {
		e.preventDefault();
		var kitref = $(this).data('ref');
		$('#kit-stap-'+ kitref).removeClass('in');
		$('#kit-stap-'+ kitref).prev().prev().addClass('in');
	});
	
	$('#kit-order').validate({
        submitHandler: function(form) {
			var params = $(form).serializeArray();
            $(form).find('select').each(function(){
                var $opt = $(this).find('option:selected');
                var ref = $opt.data('ref');
                params.push({name: 'aantal[' + ref + ']', value: 1});
            });
			params.push({name: 'source', value: 'kit'});
            $.post(window.base_url +"engine/cart.add.php", params, function(data){
				$('#cart-info-aantal').text(data.aantal);
				$('#cart-info-prijs').text(data.prijs);
				if (data.aantal > 0) {
				    $('#cart-info-next').removeClass('hidden');
                    window.location.href = window.base_seourl + '/dealers/dealer-shop/orderlist';
				} else {
					$('#cart-info-next').addClass('hidden');
				}
            }, 'json');
        }
	});
	
	$('.delete-kit-cart').click(function(e) {
		e.preventDefault();
		var params = [];
		var kit_id = $(this).data('ref');
		var kit_naam = $(this).data('naam');
		params.push({name: 'kit_id', value: kit_id } );
		params.push({name: 'kit_naam', value: kit_naam });
		$.post(window.base_url +"engine/cart.kit-delete.php", params, function(data){
			$('#cart-info-aantal').text(data.aantal);
			$('#cart-info-prijs').text(data.prijs);
			$('.kit-part-' + kit_naam).remove();
			if (data.aantal == 0) {
				window.location.href = window.base_seourl + '/dealers/dealer-shop';
			}
		}, 'json');
	});
    
    $('.copy-kit-cart').click(function(e) {
		e.preventDefault();
		if (confirm('Do you want to copy this kit?')) {
            var params = [];
            var kit_id = $(this).data('ref');
            var kit_naam = $(this).data('naam');
            params.push({name: 'kit_id', value: kit_id } );
            params.push({name: 'kit_naam', value: kit_naam });
            $.post(window.base_url +"engine/cart.kit-copy.php", params, function(data){
                window.location.href = window.location.href;
            }, 'json');
        }
	});

	$('.update-kit').click(function(e) {
		e.preventDefault();
		var params = [];
		var prod_id = $(this).data('ref');
		params.push({name: 'aantal_new', value: $(this).parent().parent().find('input').val() } );
		params.push({name: 'aantal_cur', value: $(this).data('curcount') } );
        var kit_id = $(this).data('ref');
        var kit_naam = $(this).data('naam');
        params.push({name: 'kit_id', value: kit_id } );
        params.push({name: 'kit_naam', value: kit_naam });
		params.push({name: 'source', value: 'kit'});
		$.post(window.base_url +"engine/cart.kit-copy.php", params, function(data){
                window.location.href = window.location.href;
		}, 'json');
	});
	
    
});
$(document).scroll(function() {

});

// set <img src> as a css background-image
function imagesToBackground(){
    $('.image-on-bg').each(function() {
        var imageUrl = $(this).find('img').first().attr('src');
        imageUrl = encodeURI(imageUrl);
        $(this).find('img').first().remove();
        $(this).css('background-image', 'url(' + imageUrl + ')');
    });
}


function placeholderFallback() {
    if(!Modernizr.input.placeholder) {
        $("input[placeholder], textarea[placeholder]").each(function() {
            if($(this).val()==""){
                $(this).val($(this).attr("placeholder"));
                $(this).focus(function(){
                    if($(this).val()==$(this).attr("placeholder")) {
                        $(this).val("");
                        $(this).removeClass('placeholder');
                    }
                });
                $(this).blur(function(){
                    if($(this).val()==""){
                        $(this).val($(this).attr("placeholder"));
                        $(this).addClass('placeholder');
                    }
                });
            }
        });
        $('form').submit(function(){
            // first do all the checking for required element and form validation.
            // Only remove placeholders before final submission
            var placeheld = $(this).find('[placeholder]');
            for(var i=0; i<placeheld.length; i++){
                if($(placeheld[i]).val() == $(placeheld[i]).attr('placeholder')) {
                    // if not required, set value to empty before submitting
                    $(placeheld[i]).attr('value','');
                }
            }
        });
    }
}
